



.mx-banner-and-centered-text {
    position: relative;

    img {
        width: 100%;
    }

    .text {
        position: absolute;
        top: 50%;
        transform: translateY( -50% );
        left: 20px;

        color: rgba(255,255,255,0.87);
    }
}

.blue-boxes-container {
  display: flex;

  flex-flow: row wrap;
  justify-content: space-around;

    .flex-item {
        padding: 16px;
        margin: 8px;
        border-radius: 3px;

        background-color: rgb(0,148,232);
        color: rgba(255,255,255,0.87);
        font-weight: 500;

        width: 95%;
        height: 120px;

        .fpop-media-layout-sm & {
            width: 220px;
            height: 150px;
        }

        .fpop-media-layout-gt-sm & {
            width: 200px;
            height: 150px;
        }
    }

}


// Styling for phone-transfer/activate tabs
.completed-tab,
.processing-tab {
    min-height: 400px;


    md-progress-linear .md-container,
    md-progress-linear .md-container .md-bar {
        height: 17px;
    }
}
